import { Box, Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { NavLinks } from "./NavLinks";
import { Close } from "@mui/icons-material";

const linkStyles = {
  link: {
    width: "100%",
    fontSize: "25px",
    textDecoration: "none",
    textTransform: "uppercase",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "300px",
    justifyContent: "space-evenly",
  },
};

export const MobileBurgerMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ alignSelf: "center", display: "flex" }}>
      <Drawer onClose={toggleDrawer} anchor="top" open={drawerOpen}>
        <NavLinks classes={linkStyles} handleClick={toggleDrawer} />
        <Box sx={{ alignSelf: "center" }}>
          <IconButton onClick={toggleDrawer}>
            <Close />
          </IconButton>
        </Box>
      </Drawer>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon sx={{ fontSize: "28px", margin: "10px" }} />
      </IconButton>
    </Box>
  );
};
