import { useContext } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { store } from "../../../util/store";
import { isMobile } from "../../../util/screenSizeHelpers";

const slideImages = [
  {
    url: "images/slideshow/Banner-01.jpg",
    caption: "Slide 1",
    backgroundColor: "#EAEAEC",
  },
  {
    url: "images/slideshow/Banner-02.jpg",
    caption: "Slide 1",
    backgroundColor: "#cc3399",
  },
  {
    url: "images/slideshow/Banner-03.jpg",
    caption: "Slide 1",
    backgroundColor: "#ffffff",
  },
  {
    url: "images/slideshow/Banner-04.jpg",
    caption: "Slide 1",
    backgroundColor: "#13141F",
  },
  {
    url: "images/slideshow/Banner-05.jpg",
    caption: "Slide 1",
    backgroundColor: "#E6E4E4",
  },
  {
    url: "images/slideshow/Banner-06.jpg",
    caption: "Slide 1",
    backgroundColor: "#333333",
  },
  {
    url: "images/slideshow/Banner-07.jpg",
    caption: "Slide 1",
    backgroundColor: "#9999cc",
  },
];

export const Slideshow = () => {
  const { screenSize } = useContext(store);
  const mobile = isMobile(screenSize);

  return (
    <div className="slide-container" style={{}}>
      <Fade duration={5000}>
        {slideImages.map((slideImage, index) => (
          <div
            className="each-fade"
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <img
                style={{
                  width: "100%",
                  position: "absolute",
                  zIndex: -1,
                  left: 0,
                  top: 0,
                  transform: "scale(3)",
                  height: "500px",
                  backgroundColor: `${slideImage.backgroundColor}`,
                }}
                alt={slideImage.caption}
              />
            </div>
            <img
              src={slideImage.url}
              style={{
                width: mobile ? "100%" : "50%",
                // margin: "55px",
              }}
              alt={slideImage.caption}
            />
          </div>
        ))}
      </Fade>
    </div>
  );
};
