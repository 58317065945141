import React, { useContext, useState, useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { useEffect } from "react";
import { defaultStyle, terracottaStyle } from "./MapStyling";
import { useLayoutEffect } from "react";
import { Searchbar } from "./Searchbar";
import { chipColors } from "./DestinationCard";
import ReactDOMServer from "react-dom/server";
import DestinationsPanel from "./DestinationsPanel";
import { store } from "../../util/store";
import { Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isMobile } from "../../util/screenSizeHelpers";
import MarkerClusterer from "@google/markerclustererplus";
import { Box } from "@mui/system";
import Submit from "../../pages/Submit";
import SubmitCriteria from "../../pages/SubmitCriteria";

//TODO: implement https://mui.com/material-ui/react-speed-dial/ for submit a destination, submission criteria

const useStyles = makeStyles({
  container: {
    minHeight: 0,
    flex: "1",
    display: "flex",
  },
  mobileContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  showMapButton: {
    margin: "10px",
    minHeight: "fit-content",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  mapContainer: {
    height: (props) => (isMobile(props.mobile) ? "100%" : undefined),
    // margin: (props) => (isMobile(props.mobile) ? 0 : "10px 0px"),
    // borderRadius: (props) => (isMobile(props.mobile) ? 0 : "20px"),
    // boxShadow: "12px 11px 20px 8px #0000005c",
    overflow: "hidden",
    flex: 1,
  },
  locationList: {
    display: (props) => (props.showList ? "flex" : "none"),
    flexDirection: "column",
    height: "100%",
    width: (props) => (isMobile(props.mobile) ? "100%" : "28%"),
    maxWidth: (props) => (isMobile(props.mobile) ? "100%" : "50%"),
    overflow: "auto",
    minWidth: "350px",
  },
  chip: {
    margin: "15px 20px",
    marginLeft: "0px",
  },
});

const controlButtonStyles = {
  boxShadow: "rgb(0 0 0 / 42%) 0px 0px 11px 0px",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "38px",
  margin: "8px 0 22px",
  padding: "2px 20px",
  marginRight: "50px",
  textAlign: "center",
  color: "black",
  // borderRadius: "5px",
  textDecoration: "none",
  "&:hover": {
    boxShadow: "none",
    background: "black",
  },
  border: "none",
  fontFamily: 'Work Sans,Roboto,"Helvetica Neue",Arial,sans-serif',
  textTransform: "uppercase",
  borderRadius: "3px",
  background: "white"
};

const createCenterControl = (map, styles, title, text, onClick) => {
  const controlButton = document.createElement("button");
  controlButton.type = "button";

  Object.assign(controlButton.style, styles);
  controlButton.textContent = text;
  controlButton.title = title;
  controlButton.addEventListener("click", onClick);
  return controlButton;
};

export const handleLinkClick = (destination) => {
  let url = destination.url;
  if (url.slice(0, 4) !== "http") {
    url = "http://" + url;
  }
  window.open(url, "_blank", "noopener,noreferrer");
};

const center = { lat: 50, lng: 30 };
const defaultCenter = { lat: 59.95, lng: 30.33 };

const WorldMap = (props) => {
  const {
    searchTerm: searchString,
    screenSize,
    locationsLoaded,
    countryFilter,
    typeFilter,
    types,
    destinations,
  } = useContext(store);
  const [markers, setMarkers] = useState([]);
  const searchTerm = searchString?.toLowerCase() ?? "";
  const [map, setMap] = useState(null);
  const [showList, setShowList] = useState(true);
  const [showMap, setShowMap] = useState(true);
  const [mapOptions, setMapOptions] = useState(terracottaStyle);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [submitCriteriaOpen, setCriteriaOpen] = useState(false);

  const toggleSubmit = useCallback(() => {
    setSubmitDialogOpen(!submitDialogOpen);
  }, [submitDialogOpen]);

  const toggleSubmissionCriteria = useCallback(() => {
    setCriteriaOpen(!submitCriteriaOpen);
  }, [submitCriteriaOpen]);

  let markerCluster = useRef();

  const classes = useStyles({
    showMap,
    showList,
    loaded: locationsLoaded,
    mobile: screenSize,
  });

  const handleShowMap = () => {
    setShowMap(!showMap);
    setShowList(!showList);
  };

  const applyFilter = useCallback(
    (marker) => {
      const includesTerm =
        searchTerm.length > 0
          ? marker.name.toLowerCase().includes(searchTerm) ||
            marker.city.toLowerCase().includes(searchTerm) ||
            marker?.province?.toLowerCase().includes(searchTerm) ||
            marker.country.toLowerCase().includes(searchTerm) ||
            marker.url?.toLowerCase().includes(searchTerm)
          : true;

      const includesType =
        typeFilter.length > 0
          ? marker.type?.some((type) => typeFilter.indexOf(type) >= 0)
          : true;

      const includesCountry =
        countryFilter.length > 0
          ? countryFilter.includes(marker.country)
          : true;

      return includesTerm && includesType && includesCountry;
    },
    [countryFilter, searchTerm, typeFilter]
  );

  useLayoutEffect(() => {
    setShowMap(!isMobile(screenSize));
    setShowList(true);
  }, [screenSize]);

  // switch the map style as the user zooms in to assist readability
  useEffect(() => {
    map &&
      map.addListener("zoom_changed", () => {
        if (map.getZoom() >= 15) {
          setMapOptions(defaultStyle);
        } else {
          setMapOptions(terracottaStyle);
        }
      });
  }, [map]);

  /*
  Iterate through all of our AirTable data and render each marker.
  This is called only once the map is rendered and redux state has been populated with our locations.
  */
  useEffect(() => {
    if (map) {
      const marks = destinations;
      const allMarkers = [];
      const errors = [];
      marks &&
        marks.forEach((mark) => {
          if (
            mark.lat === null ||
            mark.lng === null ||
            isNaN(mark.lat) ||
            isNaN(mark.lng) ||
            mark.lat === undefined ||
            mark.lng === undefined
          ) {
            errors.push(mark);
            return null;
          }

          // create our markers
          if (window.google && types.length) {
            const newMarker = new window.google.maps.Marker({
              map: map,
              draggable: false,
              position: { lat: mark.lat, lng: mark.lng },
              title: `${mark.url}`,
              icon: {
                url: mark.type
                  ? mark.type.length > 1
                    ? "/images/markers/multi.png"
                    : `/images/markers/${chipColors[
                        types.indexOf(mark.type[0])
                      ].replace("#", "")}_${
                        mark.sponsored ? "starred" : "plain"
                      }.png`
                  : "images/marker.png",
                scaledSize: new window.google.maps.Size(35, 35),
              },
            });
            var infowindow = new window.google.maps.InfoWindow({
              content: `
              <div style="max-width: 350px; display: flex; flex-direction: column; justify-content: space-evenly; font-family: 'Work Sans'; padding: 15px">
                <span style="font-size: 24px; font-weight: bold; text-transform: uppercase">${
                  mark.name
                }</span><br>
                <span style="font-size: 12px; font-weight: bold; text-transform: uppercase">${
                  mark.city
                }, ${mark.country}</span><br>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                ${ReactDOMServer.renderToStaticMarkup(
                  mark.type?.sort().map((type, i) => (
                    <span
                      style={{
                        backgroundColor: chipColors[types.indexOf(type)],
                        width: "fit-content",
                        padding: "10px",
                        borderRadius: "15px",
                        marginRight: "10px",
                        marginBottom: "5px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      {type}
                    </span>
                  ))
                )}
                  </div>
                </div>
              `,
              disableAutoPan: true,
            });
            newMarker.addListener("mouseover", function () {
              infowindow.open(map, newMarker);
            });
            newMarker.addListener("mouseout", function () {
              infowindow.close(map, newMarker);
            });
            newMarker.addListener("click", function () {
              handleLinkClick(mark);
            });
            allMarkers.push({
              location: mark,
              googleMarker: newMarker,
              display: true,
            });
          }
          // end marker creation
        });
      errors.length && console.log(errors);
      setMarkers(allMarkers);
    }
  }, [map, destinations, types, classes.chip]);

  // handle our filters - this is called every time a filter/searchterm changes
  useEffect(() => {
    // reset our map bounds and marker clusterer
    const bounds = window.google && new window.google.maps.LatLngBounds();
    markerCluster.current && markerCluster.current.setMap(null);
    let visibleMarkerCount = 0;

    markers.forEach((marker) => {
      // filter markers
      if (applyFilter(marker.location)) {
        marker.googleMarker.setMap(map);
        bounds.extend(marker.googleMarker.position);
        marker.display = true;
        visibleMarkerCount++;
      } else {
        marker.googleMarker.setMap(null);
        marker.display = false;
      }
    });
    map && visibleMarkerCount > 0 && map.fitBounds(bounds);
    if (visibleMarkerCount === 1) {
      map.setZoom(10);
    }

    markerCluster.current = map
      ? new MarkerClusterer(
          map,
          markers
            .filter((marker) => marker.display === true)
            .map((marker) => marker.googleMarker),
          {
            imagePath: "images/markers/clusters/m",
            gridSize: 50,
            minimumClusterSize: 8,
          }
        )
      : null;

    markerCluster.current &&
      markerCluster.current.setStyles(
        markerCluster.current.getStyles().map((style) => {
          style.textColor = "#fff";
          style.fontFamily = "'Work Sans', 'Arial', sans-serif";
          style.fontWeight = "100";
          style.textSize = "12";
          return style;
        })
      );
  }, [applyFilter, map, markers, searchTerm]);

  useEffect(() => {
    if (map && window.google) {
      const centerControlDiv = document.createElement("div");
      const submitControl = createCenterControl(
        map,
        controlButtonStyles,
        "Submit",
        "Submit A Destination",
        toggleSubmit
      );

      const criteriaControl = createCenterControl(
        map,
        controlButtonStyles,
        "Submission Criteria",
        "Submission Criteria",
        toggleSubmissionCriteria
      );

      centerControlDiv.appendChild(submitControl);
      centerControlDiv.appendChild(criteriaControl);
      map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
        centerControlDiv
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <>
      <Submit open={submitDialogOpen} toggle={toggleSubmit} />
      <SubmitCriteria
        open={submitCriteriaOpen}
        toggle={toggleSubmissionCriteria}
      />
      <div
        className={
          isMobile(screenSize) ? classes.mobileContainer : classes.container
        }
      >
        {isMobile(screenSize) && (
          <Button
            variant="contained"
            onClick={handleShowMap}
            className={classes.showMapButton}
          >
            {`${showMap ? "Hide" : "Show"} Map`}
          </Button>
        )}
        <div className={classes.locationList}>
          {!locationsLoaded || !markers.length ? (
            <Box sx={{ margin: "auto", minHeight: "200px" }}>
              <CircularProgress
                title="Loading"
                thickness={2.5}
                variant="indeterminate"
                size={100}
              />
            </Box>
          ) : (
            <>
              <Searchbar />
              <DestinationsPanel markers={markers} map={map} />
            </>
          )}
        </div>
        <div className={classes.mapContainer}>
          <GoogleMapReact
            onGoogleApiLoaded={({ map }) => {
              setMap(map);
            }}
            bootstrapURLKeys={{
              key: "AIzaSyCE2rTvxjbMDkkBSYqc3ZIjOIZSi6qR2Lc",
            }}
            defaultCenter={defaultCenter}
            defaultZoom={0}
            center={center}
            zoom={0}
            options={mapOptions}
            yesIWantToUseGoogleMapApiInternals={true}
          />
        </div>
      </div>
    </>
  );
};

export default WorldMap;
