import { useState, useCallback, useContext } from "react";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import debounce from "lodash/debounce";
import { Typography } from "@mui/material";
import Filters from "./Filters";
import { store } from "../../util/store";
import { ArrowUpwardRounded, Close, Search } from "@mui/icons-material";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "center",
    minHeight: "fit-content",
    margin: "10px",
    borderRadius: "15px",
    boxShadow: "0px 1px 4px #bebebe3d",
    border: "1px solid #00000052",
  },
  input: {
    fontWeight: "bold",
    textTransform: "uppercase",
    marginLeft: "15px",
    flex: "1 1 auto",
    padding: 0,
    "&::placeholder": {
      color: "black",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  showFilter: {
    maxHeight: "500px",
    width: "100%",
    transition: "max-height .4s ease-out",
  },
  hideFilter: {
    maxHeight: "0px",
    width: "100%",
    overflow: "hidden",
    transition: "max-height .3s ease-in",
  },
  search: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  collapseIcon: {
    display: "flex",
    margin: "0 auto",
  },
  filterLabel: {
    display: "flex",
    justifyContent: "center",
    margin: "15px",
    marginBottom: "0px",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "gray",
  },
  iconButton: {
    fontSize: "1.5rem",
  },
};

export const Searchbar = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { dispatch } = useContext(store);
  const [filtersOpen, setFiltersOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applySearchTerm = useCallback(
    debounce((value) => {
      dispatch({ type: "SET_SEARCH_TERM", payload: value });
    }, 500),
    []
  );

  const handleChange = (event) => {
    event.stopPropagation();
    const val = event.target.value;
    setSearchTerm(val);
    applySearchTerm(val.trim());
  };

  const handleClear = () => {
    setSearchTerm("");
    applySearchTerm("");
  };

  const hideFilters = (event) => {
    setFiltersOpen(false);
  };

  const showFilters = (event) => {
    setFiltersOpen(true);
  };

  return (
    <div style={styles.root}>
      <div style={styles.search} onClick={showFilters}>
        <InputBase
          placeholder="Where to?"
          sx={styles.input}
          onChange={handleChange}
          value={searchTerm}
          inputProps={{
            sx: styles.input,
          }}
          spellCheck={false}
        />
        <IconButton sx={styles.iconButton} onClick={handleClear}>
          {searchTerm.length ? <Close /> : <Search />}
        </IconButton>
      </div>
      <div style={filtersOpen === true ? styles.showFilter : styles.hideFilter}>
        <Divider />
        <Typography variant="subtitle2" sx={styles.filterLabel}>
          Filter by...
        </Typography>
        <Filters />
        <IconButton onClick={hideFilters} sx={styles.collapseIcon}>
          <ArrowUpwardRounded />
        </IconButton>
      </div>
    </div>
  );
};
