export enum ScreenSizeEnum {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
};

export const getScreenSize = (screenSize: number) => {
  if (screenSize >= 0 && screenSize <= 650) {
    return ScreenSizeEnum.xs;
  }

  if (screenSize > 650 && screenSize <= 960) {
    return ScreenSizeEnum.sm;
  }

  if (screenSize > 960 && screenSize <= 1400) {
    return ScreenSizeEnum.md;
  }

  if (screenSize > 1400 && screenSize < 1920) {
    return ScreenSizeEnum.lg;
  }

  if (screenSize >= 1920) {
    return ScreenSizeEnum.xl;
  }
};

export const isMobile = (screenSize: ScreenSizeEnum) => ["xs", "sm"].includes(screenSize);
