import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

const styles = {
  container: {
    padding: { xs: 0, md: "10px" },
    paddingLeft: { xs: "23%", sm: "10px" },
  },
  link: {
    color: "white",
    textTransform: "uppercase",
    textDecoration: "none",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    fontSize: "12px",
  },
  linkContainer: {
    fontSize: {
      xs: "10px",
      md: "12px",
    },
    padding: {
      xs: "6px",
      md: "10px",
    },
  },
};

export const FooterNavLinks = () => {
  return (
    <Grid
      container
      columnSpacing={{ xs: 0, sm: 15, md: 0 }}
      columns={{ xs: 2, sm: 4 }}
      sx={styles.container}
    >
      <Grid item xs={1} sm={1} md={1}>
        <Link style={styles.link as any} to="cwd">
          CWD Map
        </Link>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <Link
          style={styles.link as any}
          to="https://explore.moca-ny.org/connect/"
        >
          Connect
        </Link>
      </Grid>
      <Grid item xs={2} sm={2} md={2}>
        <Link style={styles.link as any} to="cwd/submit">
          Submit a Destination
        </Link>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <Link style={styles.link as any} to="https://explore.moca-ny.org">
          Explore
        </Link>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <Link
          style={styles.link as any}
          to="https://explore.moca-ny.org/about/"
        >
          About
        </Link>
      </Grid>
      <Grid item xs={2} sm={2} md={2}>
        <Link style={styles.link as any} to="terms">
          Terms of Service
        </Link>
      </Grid>
      <Grid item xs={1} sm={1} md={2}>
        <Link
          style={styles.link as any}
          to="https://explore.moca-ny.org/donate"
        >
          Donate
        </Link>
      </Grid>
      <Grid item xs={1} sm={1} md={1}>
        <Link style={styles.link as any} to="privacy">
          Privacy Policy
        </Link>
      </Grid>
    </Grid>
  );
};
