import { useLocation } from "react-router-dom";
import { Image } from "../shared/Image";
import { Box } from "@mui/material";

const styles = {
  image: {
    width: "150px",
    marginLeft: { xs: "10px" },
    marginBottom: "-10px",
  },
  text: {
    position: "absolute",
    left: "200px",
    fontSize: {
      md: "18px",
      lg: "25px",
    },
  },
};

export const BannerImage = () => {
  const path = useLocation().pathname;
  return (
    <>
      <Image
        to="/"
        sx={styles.image}
        src="/images/moca_logo.png"
        alt="Moca Logo"
      />
      {path.includes("cwd") && (
        <Box sx={styles.text}>Ceramic World Destinations</Box>
      )}
    </>
  );
};
