import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Fade,
  IconButton,
  TextField,
  Typography,
  debounce,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

const styles = {
  search: {
    [`& fieldset`]: {
      borderRadius: "25px",
      border: "2px solid black",
    },
  },
};

export const BannerSearchbar = (props: any) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const applySearchTerm = debounce((value) => {
    if (searchTerm.length > 0) {
      fetch(
        `https://explore.moca-ny.org/wp-json/wp/v2/search?search=${value}&per_page=5&page=1`
      )
        .then((response) => response.json())
        .then((jsonData) => {
          setSearchResults(jsonData.slice(0, 5));
        });
    }
  }, 1000);

  const handleChange = (event: any) => {
    event.stopPropagation();
    const val = event.target.value;
    setSearchTerm(val);
    applySearchTerm(val.trim());
  };

  const handleSearchClick = (value: boolean) => () => {
    setSearchClicked(value);
  };

  const onResultClicked = (url: string) => () => {
    setSearchTerm("");
    window.location.href = url;
  };
  return isMobile ? null : (
    <Box sx={{ paddingRight: "15px" }}>
      <IconButton
        onClick={handleSearchClick(true)}
        sx={{ display: searchClicked ? "none" : "flex" }}
      >
        <Search
          sx={{ color: "black", fontSize: { md: "14px", lg: "28px" } }}
          fontSize="small"
        />
      </IconButton>
      <Autocomplete
        options={searchResults.map((result: any) => {
          return { title: result.title, url: result.url };
        })}
        sx={{
          width: 500,
          display: searchClicked ? "flex" : "none",
          position: "absolute",
          right: 0,
          background: "white",
          marginTop: "-5px",
        }}
        filterOptions={(x) => x}
        freeSolo={true}
        noOptionsText={"No results found"}
        renderOption={(props, option: any) => {
          return (
            <li {...props} onClick={onResultClicked(option.url)}>
              <Typography style={{ color: "black" }}>{option.title}</Typography>
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <Fade in={searchClicked} timeout={500}>
              <TextField
                {...params}
                fullWidth={true}
                label={"Explore..."}
                onChange={handleChange}
                value={searchTerm}
                onBlur={handleSearchClick(false)}
                size="small"
                sx={styles.search}
              />
            </Fade>
          );
        }}
      />
    </Box>
  );
};
