import { Chip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { store } from "../../util/store";

const useStyles = makeStyles({
  card: {
    margin: "0px 20px",
    borderBottom: "1px solid #00000052",
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    cursor: "pointer",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#f4f4f47d",
    },
    position: "relative",
    minHeight: "100px",
  },
  chip: {
    marginRight: "5px",
    marginBottom: "3px",
    fontSize: "70%",
  },
  chipContainer: {
    display: "flex",
    justifyContent: (props) =>
      props.screenSize !== "md" ? "flex-end" : "flex-start",
    flexWrap: "wrap",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  star: {
    color: "#ffb831de",
  },
  detailsContainer: {
    wordBreak: "break-word",
  },
  container: {
    padding: "10px 0px",
    overflowWrap: "anywhere",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: (props) => (props.screenSize === "md" ? "column" : "row"),
  },
  clickAgain: {
    position: "absolute",
    left: "0",
    width: "100%",
    height: "100%",
    color: "white",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#0000002b",
    visibility: (props) => (props.clickedOnce ? "visible" : "hidden"),
    opacity: (props) => (props.clickedOnce ? 1 : 0),
    transition: "visibility 0.3s, opacity 0.3s ease-out",
    backdropFilter: "blur(4px)",
  },
  clickAgainText: {
    backgroundColor: "#1c1c1ca6",
    boxShadow: "1px 2px 8px #00000082",
    padding: "10px 20px",
    borderRadius: "53px",
  },
});

export const chipColors = [
  "#ffeab6",
  "#cfdfff",
  "#d0f0fd",
  "#c2f5e9",
  "#d1f7c4",
  "#fee2d5",
  "#ffdce5",
  "#ffdaf6",
  "#ede2fe",
  "#eeeeee",
  "#9cc7ff",
  "#c4f7f1",
  "#f7c4c4",
  "#cfdfff",
  "#d1f7c4",
];

const DestinationCard = (props) => {
  const { destination, googleMarker, map } = props;
  const { name, type, city, country, province } = destination;
  const { screenSize, types } = useContext(store);
  const [clickedOnce, setClickedOnce] = useState(false);

  const handleHover = () => {
    window.google.maps.event.trigger(googleMarker, "mouseover");
  };

  const handleBlur = () => {
    window.google.maps.event.trigger(googleMarker, "mouseout");
  };

  const handleClick = () => {
    if (clickedOnce) {
      window.google.maps.event.trigger(googleMarker, "click");
    } else {
      setClickedOnce(true);
      window.google.maps.event.trigger(googleMarker, "mouseover");
      map.setCenter(googleMarker.getPosition());
      map.setZoom(15);
    }
  };

  useEffect(() => {
    if (clickedOnce) {
      setTimeout(() => setClickedOnce(false), 1700);
    }
  }, [clickedOnce]);

  const classes = useStyles({ clickedOnce, screenSize: screenSize });

  return (
    <div
      className={classes.card}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
      onClick={handleClick}
    >
      <div className={classes.clickAgain}>
        <span className={classes.clickAgainText}>
          Click again to go to site
        </span>
      </div>
      <div className={classes.container}>
        <div className={classes.detailsContainer}>
          <Typography
            sx={{
              fontSize: "18px",
              textTransform: "uppercase",
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {city}, {`${province ? province + ", " : ""}`}
            {country}
          </Typography>
        </div>
        <div className={classes.chipContainer}>
          {type?.sort().map((type, index) => (
            <Chip
              style={{
                backgroundColor: chipColors[types.indexOf(type)],
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
              classes={{ root: classes.chip }}
              key={type + index}
              label={type}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DestinationCard;
