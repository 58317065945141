import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Actions, store } from "../../util/store";
import { chipColors } from "./DestinationCard";
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    backgroundColor: "white",
    marginBottom: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    marginLeft: "15px !important",
    marginRight: "15px !important",
    marginBottom: "5px !important",
  },
  noFocusHighlight: {
    "&:focus": { backgroundColor: "white" },
    display: "flex !important",
  },
  img: {
    marginRight: "15px",
    borderRadius: "100%",
  },
});

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const Filters = (props) => {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const { dispatch, countries, types } = useContext(store);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const countryParam = searchParams.get('country');
    const typeParams = searchParams.getAll('type');
    if (countryParam) {
      setSelectedCountry(countryParam);
    }
    if (typeParams) {
      setSelectedTypes(typeParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    const countryParam = searchParams.get('country');
    const typeParams = searchParams.getAll('type').filter(param => param && param !== "null");
    const cleanedParams = {};
    if (countryParam && countryParam !== "null") {
      cleanedParams.country = countryParam;
    }
    if (typeParams.length > 0) {
      cleanedParams.type = typeParams;
    }
    setSearchParams(cleanedParams)
  }, [searchParams, setSearchParams])

  useEffect(() => {
    dispatch({ type: Actions.SET_TYPE_FILTER, payload: selectedTypes });
  }, [dispatch, selectedTypes]);

  useEffect(() => {
    dispatch({ type: Actions.SET_COUNTRY_FILTER, payload: selectedCountry });
  }, [dispatch, selectedCountry]);

  const handleCountryChange = (event) => {
    setSearchParams({type: searchParams.getAll('type'), country: event.target.value})
    setSelectedCountry(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSearchParams({country: searchParams.get('country'), type: event.target.value})
    setSelectedTypes(event.target.value);
  };

  const handleDeleteChip = (value) => () => {
    const updatedTypes = selectedTypes.filter((type) => type !== value);
    setSearchParams({type: updatedTypes, country: selectedCountry})
    setSelectedTypes(updatedTypes);
  };

  const typeRenderer = (selected) => (
    <div className={classes.chips}>
      {selected.map((value) => (
        <Chip
          key={value}
          label={value}
          className={classes.chip}
          style={{ backgroundColor: chipColors[types.indexOf(value)] }}
          onDelete={handleDeleteChip(value)}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="countryFilterLabel"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          Country
        </InputLabel>
        <Select
          classes={{ select: classes.noFocusHighlight }}
          MenuProps={menuProps}
          value={selectedCountry}
          defaultValue=""
          onChange={handleCountryChange}
          label="Country"
          labelId="countryFilterLabel"
        >
          <MenuItem key="" value="">
            <ListItemText primary="No filter" />
          </MenuItem>
          {countries?.map((country) => (
            <MenuItem key={country} value={country}>
              <img
                src={`/images/flags/${country
                  .toLowerCase()
                  .replace(/ /g, "-")}.png`}
                alt={`${country}_flag_icon`}
                height="30px"
                className={classes.img}
              />
              <ListItemText primary={country} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="typeFilterLabel"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          Type
        </InputLabel>
        <Select
          MenuProps={menuProps}
          classes={{ select: classes.noFocusHighlight }}
          multiple={true}
          value={selectedTypes}
          onChange={handleTypeChange}
          renderValue={typeRenderer}
          label="Type"
          labelId="typeFilterLabel"
        >
          {types?.map((type, index) => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={selectedTypes.indexOf(type) > -1} />
              <ListItemText
                primary={
                  <Chip
                    style={{
                      backgroundColor: chipColors[types.indexOf(type)],
                    }}
                    classes={{ root: classes.chip }}
                    key={type + index}
                    label={type}
                  />
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Filters;
