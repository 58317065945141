import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import WorldMap from "./components/map/WorldMap";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import { Privacy } from "./pages/Privacy";
import { Terms } from "./pages/Terms";
import { CriteriaPage } from "./pages/SubmitCriteria";
import { SubmitPage } from "./pages/Submit";
import { Explore } from "./pages/Explore";
import { ItineraryWizard } from "./pages/ItineraryWizard";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/cwd/submit" element={<SubmitPage />} />
      <Route path="/cwd/criteria" element={<CriteriaPage />} />
      <Route path="/cwd" element={<WorldMap />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/donate"
        element={<Navigate replace to="https://explore.moca-ny.org/donate" />}
      />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/wizard" element={<ItineraryWizard />} />
    </Routes>
  );
};

export default Router;
