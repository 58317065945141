import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const styles = {
  container: {
    height: "260px",
    borderTop: "1px solid black",
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: {
      xs: "100%",
    },
    justifyContent: {
      xs: "space-evenly",
    },
  },
  text: {
    width: { xs: "70%", lg: "40%" },
    textAlign: "justify",
  },
  bold: {
    fontWeight: 400,
    fontSize: { xs: "14px", sm: "22px" },
  },
  buttons: {
    width: {
      xs: "100%",
      sm: "35%",
    },
    display: "flex",
    justifyContent: "space-evenly",
  },
};

export const AboutAndDonate = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.text}>
          <Typography sx={styles.bold}>
            The Museum of Ceramic Art - New York (MoCA/NY) advances the
            appreciation, understanding, and enjoyment of ceramics and connects
            the global clay community. MoCA/NY is a 501(c)(3) not-for-profit.
          </Typography>
        </Box>
        <Box sx={styles.buttons}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#163275",
              height: "50px",
              borderRadius: "6px",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            <Link
              to="/about"
              style={{
                color: "white",
                textDecoration: "none",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              About
            </Link>
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#163275",
              height: "50px",
              borderRadius: "6px",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            <Link
              to="/donate"
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "18px",
                minWidth: "100px",
                fontWeight: "bold",
              }}
            >
              Make A Donation
            </Link>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
