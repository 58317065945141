import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

// image props extends Box props
interface ImageProps extends React.ComponentProps<typeof Box> {
  src: string;
  alt: string;
  to?: string;
}

export const Image = (props: ImageProps) => {
  // return Box component with image props
  const { src, alt, to, ...rest } = props;
  return to ? (
    <Link to={to}>
      <Box component="img" {...rest} src={src} alt={alt} />
    </Link>
  ) : (
    <Box component="img" {...rest} src={src} alt={alt} />
  );
};
