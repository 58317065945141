import { Box, useMediaQuery, useTheme } from "@mui/material";
import { BannerImage } from "./BannerLogo";
import { NavLinks } from "./NavLinks";
import { MobileBurgerMenu } from "./MobileBurgerMenu";
import { store, Actions } from "../../util/store";
import { useCallback, useContext, useEffect, useLayoutEffect } from "react";
import { API } from "../../util/API";
import { getScreenSize } from "../../util/screenSizeHelpers";
import { useLocation } from "react-router-dom";
import analytics from "../../config/analytics";
import debounce from "lodash/debounce";
import { BannerSearchbar } from "./BannerSearchbar";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
    paddingBottom: "10px",
    paddingTop: "10px",
    flex: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.22)",
  },
  links: {
    link: {
      marginRight: {
        md: "50px",
        xl: "100px",
      },
      fontSize: { xs: "16px", lg: "20px" },
      textDecoration: "none",
      textTransform: "uppercase",
      fontWeight: 600,
      "&:hover": {
        color: "lightgray",
      },
      display: "flex",
      alignItems: "center",
    },
    container: {
      display: "flex",
      flexFlow: "wrap",
      alignItems: "center",
      width: { md: "100%" },
      justifyContent: { md: "space-evenly" },
    },
  },
};

export const BannerContainer = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { dispatch } = useContext(store);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onResize = useCallback(() => {
    debounce(() => {
      dispatch({
        type: Actions.SET_SCREEN_SIZE,
        payload: getScreenSize(window.innerWidth),
      });
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  useLayoutEffect(() => {
    dispatch({
      type: Actions.SET_SCREEN_SIZE,
      payload: getScreenSize(window.innerWidth),
    });
  }, [dispatch]);

  useEffect(() => {
    API.getAllFromDatabase().then((data) => {
      dispatch({
        type: Actions.GET_DATA,
        payload: data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    analytics.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  return (
    <Box sx={styles.container}>
      <BannerImage />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { md: "space-between" },
        }}
      >
        {isMobile ? <MobileBurgerMenu /> : <NavLinks classes={styles.links} />}
        <BannerSearchbar />
      </Box>
    </Box>
  );
};
