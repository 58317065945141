import { Close } from "@mui/icons-material";
import {
  Dialog as DialogComponent,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { isMobile } from "../../util/screenSizeHelpers";
import { store } from "../../util/store";

const useStyles = makeStyles({
  content: {
    overflow: "auto",
    padding: 0,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  widthSm: {
    maxWidth: (props) => (props.mobile ? "100%" : "50%"),
  },
});

export const Dialog = (props) => {
  const { open, toggle, title, content, contentStyles } = props;
  const { screenSize } = useContext(store);
  const mobile = isMobile(screenSize);

  const classes = useStyles({ mobile });
  return (
    <DialogComponent
      open={open}
      onClose={toggle}
      fullWidth={true}
      fullScreen={mobile}
      classes={{ paper: classes.paper, paperWidthSm: classes.widthSm }}
    >
      <DialogTitle disableTypography={true} classes={{ root: classes.title }}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={toggle}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.content }}
        sx={{ ...contentStyles }}
      >
        {content}
      </DialogContent>
    </DialogComponent>
  );
};
