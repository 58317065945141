import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const useStyles = (props: any) => ({
  display: !props.showFooter ? "hidden" : "flex",
  position: "fixed",
  bottom: 0,
  background: "white",
  border: "1px solid lightgray",
  alignItems: "center",
  justifyContent: "space-evenly",
  margin: "10px 10px",
  padding: "0px 15px",
  borderRadius: "10px",
  right: 0,
  left: 0,
});

const CookieNotice = () => {
  const [showFooter, setShowFooter] = useState(true);
  const styles = useStyles({ showFooter });

  const handleAccept = () => {
    // Set a cookie to remember the user's decision
    document.cookie = "cookieConsent=true; max-age=31536000";

    // Hide the footer
    setShowFooter(false);
  };

  const cookieConsent = document.cookie
    .split("; ")
    .find((row) => row.startsWith("cookieConsent="));

  // Check if the user has previously accepted the cookie notice
  if (cookieConsent) {
    return null;
  }

  return (
    <Box sx={{ ...styles }}>
      <p>
        We use cookies to provide necessary website functionality, improve your
        experience and to analyze our traffic. By using our website, you agree
        to our legal policies: <Link to="privacy">Privacy Policy</Link>
      </p>
      <Button
        variant="contained"
        onClick={handleAccept}
        sx={{ margin: "5px", boxShadow: "none" }}
      >
        OK
      </Button>
    </Box>
  );
};

export default CookieNotice;
