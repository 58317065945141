import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { Image } from "../components/shared/Image";
import { isMobile } from "../util/screenSizeHelpers";
import { store } from "../util/store";

const useStyles = makeStyles({
  paperRoot: {
    maxWidth: "1000px",
  },
});

const styles = {
  header: {
    backgroundColor: "#BA0016",
    width: "100%",
    display: "flex",
    height: "100px",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    color: "white",
    textTransform: "uppercase",
    fontWeight: 700,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 10%",
    alignItems: "center",
  },
  rowOne: {
    display: "flex",
    flexDirection: "row",
    marginBottom: { lg: "0px" },
    alignItems: "center",
  },
  rowTwo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: {
    width: {
      xs: "100%",
      lg: "500px",
    },
    height: {
      md: "400px",
      lg: "600px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: "40px",
  },
  image: {
    maxWidth: {
      xl: "400px",
      lg: "400px",
    },
    display: {
      xs: "none",
      md: "block",
    },
  },
  paragraph: {
    fontSize: { xs: "16px", lg: "16px" },
    fontWeight: 700,
    textAlign: { xs: "center", sm: "justify" },
  },
  paragraph2: {
    fontSize: { xs: "16px", lg: "16px" },
    fontWeight: 700,
    lineHeight: "16.42px",
    textAlign: { xs: "center", sm: "justify" },
  },
  title: {
    fontSize: { xs: "30px", md: "32px" },
    fontWeight: "bold",
    lineHeight: "30px",
    marginBottom: { xs: "30px", md: 0 },
    textAlign: { xs: "center", sm: "left" },
  },
  bioText: {
    textAlign: { xs: "center", sm: "justify" as any },
    marginTop: "45px",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: { xs: undefined, md: "350px 350px" },
    gridColumnGap: "50px",
    gridRowGap: "50px",
    justifyContent: "center",
    margin: "50px",
  },
  box: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
};

const bios: { [key: string]: string } = {
  judy: "Judith S Schwartz, Ph.D. is a professor emeritus from New York University where she taught for more than 30 years. She is the author of “Confrontational Ceramics” and has written numerous artist reviews, catalog essays, and articles in international journals. She serves as juror and consultant to various international competitions and is Vice president of the International Academy of Ceramics. She is an honorary member of the National Council on Education in the Ceramic Arts and was awarded their teaching excellence award. She is honored by the J. D. Rockefeller III Foundation, Fulbright Association, New York Art Teachers Association, and the Everson and Renwick Museums. https://www.judyschwartz.com/",
  bruce:
    "Bruce M Sherman has a studio practice in New York City and Sagaponock Long Island. He has participated in numerous group and solo exhibitions worldwide and is represented by the Nicelle Beauchene Gallery in Tribeca NYC. He is an avid art collector with an array of works encompassing many disciplines. In addition to his ceramic practice, Bruce has retired from his dental practice and from teaching at NYU College of Dentistry, however, he’s still active as the CEO of the Dental Insurance Corporation. Bruce’s interest in art is based on his personal quest to explore clay’s endless possibilities for expressing ideas in sculptural forms. He has been a board member since MoCA/NY’s founding. Studio@brucemsherman.com",
  rick: "Richard Rogers holds an MBA from Ohio State University (1979) and a BA in Biology and Chemistry from Hiram College (1977). Rick began his career in 1980 as founder and president of Tribute, a software company based in Akron, Ohio. He assumed leadership of B. W. Rogers Company, a manufacturing and distribution company in 1994. Rick’s philanthropy includes supporting the Akron Children’s Hospital; the Akron Art Museum; the Intermuseum Conservancy Association; and the Boys and Girls Club of the Western Reserve. In 2017, Rick created the art nonprofit Curated Storefront which has secured and managed funds to launch installations in downtown Akron. Rick is deeply engaged in the arts and is a leading collector of contemporary ceramics from Asia and the US and twentieth-century American and European design.",
  will: "Hailing from Austin, Texas, Will Resen is responsible for the development and digital infrastrucure of MOCA-NY and the Ceramic World Destinations web application. He currently works as a Senior Developer at Vitech Systems Group and a Senior Engineer at Iternal Technologies, and volunteers as a board member and director at the Museum of Ceramic Art - New York.",
  ilsy: "Ilsy Jeon is a multidisciplinary artist and writer born and based in New York. She studied drawing and painting at Pratt Institute for two years and received her B.F.A Hons in Sculpture and Environmental Art from Glasgow School of Art where she completed a dissertation about the epistemology of museums through a postcolonial lens. She has participated in residencies in Iceland, Hungary, and the United States and has exhibited in New York, Glasgow, Seydisfjordur, and Budapest.",
  jackie:
    "Jacqueline Tse is an artist and designer. She was awarded the Multicultural Fellowship in 2020 from the National Council on Education in the Ceramic Arts. Her work was selected for the 61st Faenza Biennale at the International Museum of Ceramics in Faenza, Italy, and has been featured in various publications about contemporary ceramic art. She is also a seasoned designer with over 15 years of experie nce in design consulting, development, and project management in branded consumer products. As a global citizen and tech-savvy individual, she is excited to work with MoCA/NY to enhance and enrich the digital experience of Ceramic World Destinations to all the ceramic art lovers out there.",
  abby: "Abner Dumoff  has been involved with computers since they were programmed with punch cards and paper tape. He learned Computer Science at Yale University under the tutelage of several graduate students, while he was still in high school. Over the years, he has been a pioneer in HVAC, Desktop Publishing, Video Special Effects, and Television Post-production Scheduling systems.  A long time consultant for Professor Schwartz, Abby was involved with the realization of the concept of the Ceramic World Destinations map, and the architecture of its application.  https://abbydigital.com/",
  carla:
    "Carla Aviles-Davidson holds an MBA in Marketing from the Zicklin School of Business and BA in English from the University of North Carolina at Chapel Hill. Her lifelong passion for learning and storytelling fuels creative digital marketing initiatives that emphasize human connection. She has previously worked for the McCann agency pitching campaign recommendations for the Verizon and HomeGoods brands. When not diligently researching consumer trends and working on digital marketing projects, Carla is either reading an ever-growing pile of books or cooking some new, delicious recipe. She currently lives with her husband in Austin, Texas.",
  caroline:
    "Caroline Cheng is an artist and curator, whose promotion of Chinese ceramic arts over decades has earned renown in China and throughout the world. As an artist, Caroline has held solo exhibitions in Sotheby’s Hong Kong (2013) and New York (2015). Her work has been collected by the British Museum, Fitzwilliam Museum in Cambridge, San Francisco Asian Art Museum, and the Boston Museum of Fine Art. Cheng has received prizes for her work in the Keramiak Museum in Westerwald, Germany (2010), Clay and Glass Film Festival in Montpellier France (2010), Mino International Ceramic Award 2017, and also received the “Outstanding Achievement Award” from the American National Council on Education for the Ceramic Arts (NCECA) in 2014 and the Japan Good Design Award for Community Development in 2020. Caroline Cheng is the owner of the Pottery Workshop in Hong Kong, Shanghai, Jingdezhen, and Dali.",
  carol:
    "Carol Green is a textile designer who operated a manufacturing business that designed and produced placemats, canvas bags, and accessories. She has also worked in fashion and interior design and served on the Watershed Center for the Ceramic Arts board. Green is an avid collector and appreciator of all art mediums, traveling and collecting with the Art Jewelry Forum, James Renwick Alliance for Craft, Corning Museum Of Glass, Glass Art Society, MFA Boston Fashion and Textile Arts Council, and NCECA.",
};

interface BiographyProps {
  img: string;
  name: string;
  bioId: string;
  role: string;
}

const Biography = (props: BiographyProps) => {
  const { name, img, bioId, role } = props;
  const { screenSize } = useContext(store);
  const [bioVisible, setBioVisible] = useState<boolean>(false);
  const mobile = isMobile(screenSize);
  const classes = useStyles();

  const toggleBio = () => {
    setBioVisible(!bioVisible);
  };

  return (
    <Box sx={{ display: "inline-block", margin: "25px", cursor: "pointer" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Dialog
          open={bioVisible}
          onClose={toggleBio}
          fullScreen={mobile}
          classes={{ paper: classes.paperRoot }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: "0px",
              background: "white",
            }}
          >
            <IconButton aria-label="close" onClick={toggleBio}>
              <Close />
            </IconButton>
          </DialogTitle>
          <Box
            sx={{
              height: "75%",
              margin: "0px 25px 25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={img}
              alt={name as string}
              style={{
                borderRadius: "100%",
                height: "200px",
                width: "200px",
                objectFit: "cover",
                marginBottom: "25px",
              }}
            />
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {name}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {role}
            </Typography>
            <Typography variant="h6" sx={styles.bioText}>
              {bios[bioId]}
            </Typography>
          </Box>
        </Dialog>
        <img
          src={img}
          alt={name as string}
          style={{
            borderRadius: "100%",
            height: "150px",
            width: "150px",
            objectFit: "cover",
            marginBottom: "15px",
          }}
          onClick={toggleBio}
        />
        <Typography>{name}</Typography>
      </Box>
    </Box>
  );
};

export const About = () => {
  return (
    <Box>
      <Box sx={styles.header}>
        <Typography sx={styles.headerText} variant="h3">
          About
        </Typography>
      </Box>
      <Box sx={styles.gridContainer}>
        <Box sx={styles.box}>
          <Typography sx={styles.title}>Mission</Typography>
          <Typography paragraph sx={styles.paragraph}>
            The Museum of Ceramic Art - New York (MoCA/NY) advances the
            appreciation, understanding, and enjoyment of ceramics through
            exhibitions, research, education, and scholarship. MoCA/NY operates
            as a virtual museum.
          </Typography>
          <Typography paragraph sx={styles.paragraph}>
            Our primary focus is to develop and support the platform Ceramic
            World Destinations (CWD). CWD increases visibility for ceramics,
            provides professional exposure, and introduces new populations to
            the expressive nature of clay.
          </Typography>
        </Box>
        <Box sx={{ ...styles.box, ...styles.image }}>
          <Image
            width="100%"
            src="./images/about/about_img1.PNG"
            alt="artwork image"
          />
        </Box>
        <Box sx={{ ...styles.box, ...styles.image }}>
          <Image
            width="100%"
            src="./images/about/about_img2.PNG"
            alt="artwork image"
          />
        </Box>
        <Box sx={styles.box}>
          <Typography sx={{ ...styles.title, marginBottom: "15px" }}>
            About Ceramic World Destinations (CWD)
          </Typography>
          <Typography paragraph sx={styles.paragraph2}>
            Ceramic World Destinations reveals the global, diverse, and
            universal appeal of clay. The thousands of destinations listed are
            alternative means for “travel,” opening new opportunities for
            education, exploration, and enjoyment.
          </Typography>
          <Typography paragraph sx={styles.paragraph2}>
            Destinations include museums, galleries, design stores,
            manufacturers, institutions, cultural sites, and other
            ceramic-oriented venues. Also included are artist residency programs
            and artist's studios open to the public.
          </Typography>
          <Typography paragraph sx={styles.paragraph2}>
            It is simple to navigate. A map of the world with URL links is shown
            upon opening. Navigate the map directly or use the search boxes. No
            destination is ever more than a few clicks away.
          </Typography>
          <Typography paragraph sx={styles.paragraph2}>
            Additionally, you may submit destinations by clicking the button
            below:
          </Typography>
          <Button
            sx={{
              backgroundColor: "#163275",
              height: "50px",
              width: "250px",
              alignSelf: "center",
              borderRadius: 0,
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() =>
              window.open(
                "https://airtable.com/embed/shrDxzxahvslUlPGj?backgroundColor=pink",
                "_blank"
              )
            }
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Submit A Destination
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid black",
          height: "fit-content",
          margin: "100px 10%",
          borderRadius: "15px",
          padding: "20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            ...styles.title,
            textAlign: "center",
            padding: "5px",
            textTransform: "uppercase",
          }}
        >
          Team
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexFlow: "wrap",
            justifyContent: "center",
          }}
        >
          <Biography
            img={"./images/about/headshots/Judith Schwartz.jpg"}
            name="Judith Schwartz"
            bioId="judy"
            role="President"
          />
          <Biography
            img={"./images/about/headshots/Bruce Sherman.jpg"}
            name="Bruce Sherman"
            bioId="bruce"
            role="Board Member"
          />
          <Biography
            img={"./images/about/headshots/Rick Rogers.jpg"}
            name="Rick Rogers"
            bioId="rick"
            role="Board Member"
          />
          <Biography
            img={"./images/about/headshots/Caroline Cheng.jpg"}
            name="Caroline Cheng"
            bioId="caroline"
            role="Board Member"
          />
          <Biography
            img={"./images/about/headshots/Carol Green.jpg"}
            name="Carol Green"
            bioId="carol"
            role="Board Member"
          />
          <Biography
            img={"./images/about/headshots/Will Resen.jpg"}
            name="Will Resen"
            bioId="will"
            role="Director"
          />
          <Biography
            img={"./images/about/headshots/Leigh Mickelson.jpg"}
            name="Leigh Taylor Mickelson"
            bioId="leigh"
            role="Advisory Board Member"
          />
          <Biography
            img={"./images/about/headshots/Ilsy Jeon.jpeg"}
            name="Ilsy Jeon"
            bioId="ilsy"
            role="Creative Director and Chief Content Officer"
          />
          <Biography
            img={"./images/about/headshots/Jackie Tse.jpg"}
            name="Jacqueline Tse"
            bioId="jackie"
            role="Chief Experience Officer"
          />
          <Biography
            img={"./images/about/headshots/Carla Aviles.jpg"}
            name="Carla Aviles"
            bioId="carla"
            role="Chief Marketing officer"
          />
          <Biography
            img={"./images/about/headshots/Yeesoon Jeon.jpg"}
            name="Yeesoon Jeon"
            bioId="yeesoon"
            role="Designer"
          />
          <Biography
            img={"./images/about/headshots/Abby Dumoff.jpg"}
            name="Abby Dumoff"
            bioId="abby"
            role="..."
          />
        </Box>
      </Box>
    </Box>
  );
};
