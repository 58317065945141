import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { Dialog } from "../../../shared/Dialog";

const styles = {
  container: {
    height: { xs: "400px", sm: "350px", md: "200px" },
    backgroundColor: "#FEF8C4",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    margin: { xs: "0", sm: "100px 20%" },
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  text: {
    width: { xs: "75%", sm: "50%" },
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: "3px",
    textAlign: { xs: "center", sm: "left" },
  },
  submitContainer: {
    width: {
      xs: "100%",
      sm: "50%",
    },
    display: "flex",
    justifyContent: "center",
  },
  button: {
    color: "black",
  },
  root: {
    display: "flex",
    alignItems: "center",
    width: "20rem",
    height: "4rem",
    marginLeft: "15px",
    boxShadow: "none",
    border: "2px solid black",
  },
  inputBase: {
    marginLeft: "7px",
    fontSize: "16px",
    color: "black",
    flex: 1,
    "&::placeholder": {
      color: "black",
    },
    fontWeight: "bold",
  },
};

export const SubscribeCTA = () => {
  const [dialogOpen] = useState<boolean>(false);

  const toggleDialog = () => {
    window.location.replace("https://explore.moca-ny.org/subscribe-form/");
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.text}>
          <Typography variant="h5" fontWeight={700}>
            SIGN UP FOR OUR NEWSLETTER TO KEEP UP TO DATE WITH ALL THINGS
            CERAMIC!
          </Typography>
        </Box>
        <Dialog
          open={dialogOpen}
          toggle={toggleDialog}
          title="Subscribe to MoCA/NY's newsletter"
          content={
            <div id="mc_embed_signup">
              <form
                action="https://moca-ny.us14.list-manage.com/subscribe/post?u=beb9246a61eba9444d7f49b56&amp;id=37d740b56f&amp;f_id=00d6a2e0f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                onSubmit={toggleDialog}
              >
                <div id="mc_embed_signup_scroll">
                  <div className="indicates-required">
                    <span className="asterisk">*</span> indicates required
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">
                      Email Address <span className="asterisk">*</span>
                    </label>
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      required={true}
                    />
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-FNAME">
                      First Name <span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      name="FNAME"
                      className="required text"
                      id="mce-FNAME"
                      required={false}
                    />
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-LNAME">Last Name </label>
                    <input
                      type="text"
                      name="LNAME"
                      className="text"
                      id="mce-LNAME"
                    />
                  </div>
                  <div hidden={true}>
                    <input type="hidden" name="tags" value="8114969" />
                  </div>
                  <div id="mce-responses" className="clearfalse">
                    <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: "none" }}
                    ></div>
                    <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div
                    aria-hidden="true"
                    style={{ position: "absolute", left: "-5000px" }}
                  >
                    <input
                      type="text"
                      name="b_beb9246a61eba9444d7f49b56_37d740b56f"
                      tabIndex={-1}
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                      value="Subscribe"
                    />
                  </div>
                </div>
              </form>
            </div>
          }
        ></Dialog>
        <Button
          variant="contained"
          onClick={toggleDialog}
          sx={{
            backgroundColor: "#163275",
            height: "50px",
            borderRadius: "6px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        >
          <span
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Sign Me Up
          </span>
        </Button>
      </Box>
    </Box>
  );
};
