import { createContext, useReducer, useState, useEffect } from "react";
import update from "immutability-helper";
import { ScreenSizeEnum } from "./screenSizeHelpers";

interface AppState {
  destinations: any[],
  countries: any[],
  types: any[],
  typeFilter: any[],
  countryFilter: any[],
  searchTerm: string,
  screenSize: ScreenSizeEnum,
  showFooter: boolean,
  locationsLoaded: boolean,
  dispatch?: any
}

const initialState: AppState = {
  destinations: [],
  countries: [],
  types: [],
  typeFilter: [],
  countryFilter: [],
  searchTerm: "",
  screenSize: ScreenSizeEnum.md,
  showFooter: false,
  locationsLoaded: false
};

enum Actions {
  GET_DATA = "GET_DATA",
  SET_TYPE_FILTER = "SET_TYPE_FILTER",
  SET_COUNTRY_FILTER = "SET_COUNTRY_FILTER",
  SET_SEARCH_TERM = "SET_SEARCH_TERM",
  SET_SCREEN_SIZE = "SET_SCREEN_SIZE",
  SET_SHOW_FOOTER = "SET_SHOW_FOOTER",
};

interface Action {
  payload: any;
  type: Actions;
}

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }: any) => {
  const [locationsLoaded, setLocationsLoaded] = useState(false);

  const [state, dispatch] = useReducer((state: AppState, action: Action): AppState => {
    switch (action.type) {
      case Actions.GET_DATA:
        return update(state, {
          destinations: { $set: action.payload.destinations },
          types: { $set: action.payload.types },
          countries: { $set: action.payload.countries },
          locationsLoaded: { $set: true }
        });
      case Actions.SET_TYPE_FILTER:
        return update(state, { typeFilter: { $set: action.payload } });
      case Actions.SET_COUNTRY_FILTER:
        return update(state, { countryFilter: { $set: action.payload } });
      case Actions.SET_SEARCH_TERM:
        return update(state, { searchTerm: { $set: action.payload } });
      case Actions.SET_SCREEN_SIZE:
        return update(state, { screenSize: { $set: action.payload } });
      case Actions.SET_SHOW_FOOTER:
        return update(state, { showFooter: { $set: action.payload } });
      default:
        return initialState;
    }
  }, initialState);

  useEffect(() => {
    if (Object.keys(state.destinations).length) {
      setLocationsLoaded(true);
    }
  }, [state.destinations]);

  return (
    <Provider value={{ ...state, dispatch, locationsLoaded }}>{children}</Provider>
  );
};

export { store, StateProvider, Actions };
