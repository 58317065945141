import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { chipColors } from "../../../map/DestinationCard";

const styles = {
  container: {
    height: "fit-content",
    backgroundColor: "#ecfaff",
    display: "flex",
    flexDirection: "column",
    padding: "50px",
    justifyContent: "space-evenly",
    boxShadow: "-4px 1px 47px -21px rgba(0, 0, 0, 0.16)",
    overflow: "hidden",
    alignItems: "center",
  },
  text: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: { xs: "100%", md: "400px" },
  },
  exploreText: {
    marginBottom: "5%",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  images: {
    display: { xs: "none", md: "flex" },
    width: "400px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  exploreContent: {
    fontWeight: 700,
    lineHeight: "1.4",
    textAlign: "justify",
  },
  rowOne: {
    display: "flex",
    flexDirection: "row",
  },
  rowTwo: {
    display: "flex",
    flexDirection: "row",
  },
  headerText: {
    color: "black",
    textTransform: "uppercase",
    fontWeight: "800",
    marginBottom: "25px",
  },
  image: {
    width: "140px",
    height: "140px",
  },
  link: {
    textDecoration: "none",
    color: "black",
    textAlign: "center",
  },
};

export const ExploreCTA = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    fetch(
      "https://explore.moca-ny.org/wp-json/wp/v2/posts?_embed=true&per_page=100"
    )
      .then((response) => response.json())
      .then((jsonData) => {
        setData(
          jsonData
            .filter((article: any) => article.categories.includes(363))
            .slice(0, 5)
        );
      });
  }, []);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.headerText} variant="h4">
        Explore
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-evenly",
          overflowX: "auto",
        }}
      >
        {data?.map((article: any, index: number) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "140px",
                "&:hover": {
                  cursor: "pointer",
                  opacity: "50%",
                },
                margin: "0px 10px",
                padding: "25px",
                background: chipColors[index],
              }}
            >
              <a href={article.link} style={styles.link as any}>
                <img
                  src={article._embedded["wp:featuredmedia"][0].source_url}
                  alt="explore page call to action"
                  style={styles.image}
                />
                <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                  {article.title.rendered}
                </Typography>
              </a>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
