import { ref, get } from "firebase/database";
import { db, storage } from "../config/firebase";
import { getDownloadURL, ref as storageRef } from "firebase/storage";

const decryptObject = (obj, index) => {
  const decryptedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "string") {
      try {
        decryptedObj[key] = decodeURIComponent(atob(value));
      } catch {
        decryptedObj[key] = value;
      }
    } else {
      decryptedObj[key] = value;
    }
  }
  return decryptedObj;
};

export const API = {
  getAllFromDatabase: async () => {
    const destUrl = await getDownloadURL(
      storageRef(storage, "destinations/airtable_data.json")
    );
    const destinations = await fetch(destUrl).then((response) =>
      response.json()
    );
    const countries = (await get(ref(db, "countries"))).val();
    const types = (await get(ref(db, "types"))).val();

    return {
      destinations: destinations.map(decryptObject),
      countries,
      types,
    };
  },
};
