import { Box } from "@mui/material";
import { MapCTA } from "../components/content/home/calls to action/MapCTA";
import { Slideshow } from "../components/content/home/Slideshow";
import { SubscribeCTA } from "../components/content/home/calls to action/SubscribeCTA";
import { ExploreCTA } from "../components/content/home/calls to action/ExploreCTA";
import { AboutAndDonate } from "../components/content/home/AboutAndDonate";

export const Home = () => {
  return (
    <Box>
      <Slideshow />
      <MapCTA />
      <SubscribeCTA />
      <ExploreCTA />
      <AboutAndDonate />
    </Box>
  );
};
