import { createTheme, ThemeProvider } from "@mui/material";
import { BannerContainer } from "./components/banner/BannerContainer";
import { Footer } from "./components/footer/Footer";
import Router from "./Router";
import { StateProvider } from "./util/store";
import CookieNotice from "./components/footer/CookieNotice";

const breakpoints = {
  values: {
    xs: 0,
    sm: 750,
    md: 1040,
    lg: 1400,
    xl: 1636,
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#d3d3d3",
    },
  },
  breakpoints,
  typography: {
    fontFamily: [
      "Work Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

const App = () => {
  return (
    <StateProvider>
      <ThemeProvider theme={theme}>
        <BannerContainer />
        <Router />
        <Footer />
        <CookieNotice />
      </ThemeProvider>
    </StateProvider>
  );
};

export default App;
