import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCsbG5PRB3xkouwxflbXWHx-ek0H5JZXzI",
  authDomain: "moca-ny.firebaseapp.com",
  projectId: "moca-ny",
  storageBucket: "moca-ny.appspot.com",
  messagingSenderId: "825146742672",
  appId: "1:825146742672:web:f7729b178527821ee0c515",
  measurementId: "G-4F0DHXQPPQ"
};
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);