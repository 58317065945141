import { Typography, Box, FormControl, Button, TextField } from "@mui/material";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../config/firebase";

const functions = getFunctions(firebaseApp);
const submitContactMessage = httpsCallable(functions, "submitContactMessage");

const styles = {
  header: {
    backgroundColor: "#EA3A45",
    width: "100%",
    display: "flex",
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    color: "white",
    textTransform: "uppercase",
    fontWeight: 700,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    margin: {
      md: "5% 20%",
    },
    alignItems: "center",
    flex: 1,
  },
  formContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: "400px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "400px",
    border: "1px solid #EA3A45",
    padding: "15px",
    boxSizing: "border-box",
  },
  captionInfo: {
    textAlign: "left",
    width: "400px",
    fontSize: "10px",
    fontWeight: "bold",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: { xs: undefined, md: "500px 500px" },
    gridColumnGap: "20px",
    gridRowGap: "20px",
    justifyItems: "center",
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
};

interface Message {
  subject: string;
  body: string;
  email: string;
  name: string;
}

const validateFormInputs = (message: Message): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  if (!message.subject.trim()) {
    errors.subject = "Please enter a subject";
  }

  if (!message.body.trim()) {
    errors.body = "Please enter a message";
  }

  if (!message.name.trim()) {
    errors.name = "Please enter your name";
  }

  if (!message.email.trim()) {
    errors.email = "Please enter an email address";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(message.email.trim())) {
    errors.email = "Please enter a valid email address";
  }

  return errors;
};

export const Contact = () => {
  const [sent, setSent] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [message, setMessage] = useState<Message>({
    subject: "",
    body: "",
    email: "",
    name: "",
  });

  const handleChange =
    (fieldType: keyof Message) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMessage = Object.assign({}, message);
        updatedMessage[fieldType] = event.target.value;
        setMessage(updatedMessage);
      };

  const handleSubmit = () => {
    const errors = validateFormInputs(message);
    if (Object.values(errors).length > 0) {
      setErrors(errors);
    } else {
      submitContactMessage(message);
      setSent(true);
    }
  };

  return (
    <>
      <Box sx={styles.header}>
        <Typography sx={styles.headerText} variant="h3">
          Contact
        </Typography>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.gridContainer}>
          <Box sx={styles.box}>
            <img
              style={{ width: "400px" }}
              src="./images/contact/editedphone.png"
              alt="Artwork by"
            />
          </Box>
          <Box sx={styles.box}>
            {!sent ? (
              <FormControl sx={styles.form}>
                <TextField
                  onChange={handleChange("name")}
                  value={message.name}
                  placeholder="Name"
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  onChange={handleChange("email")}
                  value={message.email}
                  placeholder="Email"
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <TextField
                  onChange={handleChange("subject")}
                  value={message.subject}
                  placeholder="Subject"
                  error={!!errors.subject}
                  helperText={errors.subject}
                />
                <TextField
                  onChange={handleChange("body")}
                  value={message.body}
                  placeholder="Message"
                  rows={5}
                  multiline={true}
                  error={!!errors.body}
                  helperText={errors.body}
                />
                <Button
                  onClick={handleSubmit}
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    height: "50px",
                    borderRadius: 0,
                    boxShadow: "none",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Submit
                  </Typography>
                </Button>
              </FormControl>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textJustify: "center",
                  width: "400px",
                }}
              >
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: "200px",
                    color: "#6fbf6c",
                    marginBottom: "50px",
                  }}
                />
                <Typography>
                  Thank you for contacting us. We will get back to you as soon
                  as possible.
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={styles.box}>
            <Box sx={styles.captionInfo}>
              <span>WORK COURTESY OF SHALENE VALENZUELA</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
