import { Box, Typography } from "@mui/material";

const styles = {
  header: {
    width: "100%",
    display: "flex",
    height: "200px",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    fontWeight: 700,
    fontSize: {
      xs: "36px",
      md: undefined,
    },
  },
};

export const Privacy = () => {
  return (
    <Box sx={{ margin: "25px" }}>
      <Box sx={styles.header}>
        <Typography sx={styles.headerText} variant="h3">
          Privacy Policy
        </Typography>
      </Box>
      <Box sx={{ margin: { xs: "5%", md: "0px 25%" }, fontWeight: 500 }}>
        <Typography>
          This Privacy Policy describes how www.moca-ny.org (the “Site” or “we”)
          collects, uses, and discloses your Personal Information when you visit
          or make a purchase from the Site.
        </Typography>
        <h4>Contact</h4>
        After reviewing this policy, if you have additional questions, want more
        information about our privacy practices, or would like to make a
        complaint, please contact us by e-mail at privacy@moca-ny.org or by mail
        using the details provided below: PO Box #43, Armonk, NY 10504, United
        States
        <h4>Collecting Personal Information</h4>
        <Typography>
          When you visit the Site, we collect certain information about your
          device, your interaction with the Site, and information necessary to
          process your purchases. We may also collect additional information if
          you contact us for customer support. In this Privacy Policy, we refer
          to any information about an identifiable individual (including the
          information below) as “Personal Information”. See the list below for
          more information about what Personal Information we collect and why.
        </Typography>
        <u>Device information</u>
        <ul>
          <li>
            Purpose of collection: to load the Site accurately for you, and to
            perform analytics on Site usage to optimize our Site.
          </li>

          <li>
            Source of collection: Collected automatically when you access our
            Site using cookies, log files, web beacons, tags, or pixels.
          </li>

          <li>
            Disclosure for a business purpose: shared with our processor Paypal.
          </li>

          <li>
            Personal Information collected: version of web browser, IP address,
            time zone, cookie information, what sites or products you view,
            search terms, and how you interact with the Site.
          </li>
        </ul>
        <u>Donation information</u>
        <ul>
          <li>
            Purpose of collection: to provide products or services to you to
            fulfill our contract, to process your payment information, arrange
            for shipping, and provide you with invoices and/or order
            confirmations, communicate with you, screen our orders for potential
            risk or fraud, and when in line with the preferences you have shared
            with us, provide you with information or advertising relating to our
            products or services.
          </li>

          <li>Source of collection: collected from you.</li>

          <li>
            Disclosure for a business purpose: shared with our processor Paypal.
          </li>

          <li>
            Personal Information collected: name, billing address, shipping
            address, payment information (including credit card numbers, email
            address, and phone number).
          </li>
        </ul>
        <u>Donor and customer support information</u>
        <ul>
          <li>
            Examples of Personal Information collected: name, billing address,
            shipping address, payment information (including credit card
            numbers), email address, and phone number.
          </li>

          <li>Purpose of collection: to provide customer support.</li>

          <li>Source of collection: collected from you.</li>

          <li>
            Disclosure for a business purpose: shared with our processor
            Paypal.
          </li>
        </ul>
        <h5>Minors</h5>
        The Site is not intended for individuals under the age of 13. We do not
        intentionally collect Personal Information from children. If you are the
        parent or guardian and believe your child has provided us with Personal
        Information, please contact us at the address above to request deletion.
        <h3>Sharing Personal Information</h3>
        We share your Personal Information with service providers to help us
        provide our services and fulfill our contracts with you, as described
        above. For example:
        <li>
          We use Google to power our experiences. You can read more about how
          Google uses your Personal Information here:
          https://policies.google.com/privacy.
        </li>
        <li>
          We may share your Personal Information to comply with applicable laws
          and regulations, to respond to a subpoena, search warrant or other
          lawful request for information we receive, or to otherwise protect our
          rights.
        </li>
        {/* <li>[Will- are you using any third party tools/API? INSERT INFORMATION ABOUT OTHER SERVICE PROVIDERS]</li> */}
        <h3>Behavioral Advertising</h3>
        As described above, we use your Personal Information to provide you with
        targeted advertisements or marketing communications we believe may be of
        interest to you. For example:
        <li>
          We use Google Analytics to help us understand how our customers use
          the Site. You can read more about how Google uses your Personal
          Information here: https://www.google.com/intl/en/policies/privacy/.
          You can also opt-out of Google Analytics here:
          https://tools.google.com/dlpage/gaoptout.
        </li>
        {/* <li>[Will– are you using any 3rd party apps in addition to google analytics and google ads? We share information about your use of the Site, your purchases, and your interaction with our ads on other websites with our advertising partners. We collect and share some of this information directly with our advertising partners, and in some cases through the use of cookies or other similar technologies (which you may consent to, depending on your location).</li>

  <li>Will - [INSERT OTHER ADVERTISING SERVICES USED]</li> */}
        For more information about how targeted advertising works, you can visit
        the Network Advertising Initiative’s (“NAI”) educational page at
        https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        You can opt out of targeted advertising by:
        <li>GOOGLE - https://www.google.com/settings/ads/anonymous</li>
        Additionally, you can opt out of some of these services by visiting the
        Digital Advertising Alliance’s opt-out portal at:
        https://optout.aboutads.info/.
        <h3>Using Personal Information</h3>
        We use your personal Information to provide our services to you, which
        includes: offering products for sale, processing payments, shipping and
        fulfillment of your order, and keeping you up to date on new products,
        services, and offers.
        <h5>Lawful basis</h5>
        Pursuant to the General Data Protection Regulation (“GDPR”), if you are
        a resident of the European Economic Area (“EEA”), we process your
        personal information under the following lawful bases:
        <li>Your consent;</li>
        <li>The performance of the contract between you and the Site;</li>
        <li>Compliance with our legal obligations;</li>
        <li>To protect your vital interests;</li>
        <li>To perform a task carried out in the public interest;</li>
        <li>
          For our legitimate interests, which do not override your fundamental
          rights and freedoms.
        </li>
        <h5>Retention</h5>
        When you donate or place an order through the Site, we will retain your
        Personal Information for our records unless and until you ask us to
        erase this information. For more information on your right of erasure,
        please see the ‘Your rights’ section below.
        <h5>Automatic decision-making</h5>
        If you are a resident of the EEA, you have the right to object to
        processing based solely on automated decision-making (which includes
        profiling), when that decision-making has a legal effect on you or
        otherwise significantly affects you. We engage in fully automated
        decision-making that has a legal or otherwise significant effect using
        customer data. Our processors use limited automated decision-making
        to prevent fraud that does not have a legal or otherwise significant
        effect on you. Services that include elements of automated
        decision-making include:
        <li>
          Temporary blacklist of IP addresses associated with repeated failed
          transactions. This blacklist persists for a small number of hours.
        </li>
        <li>
          Temporary blacklist of credit cards associated with blacklisted IP
          addresses. This blacklist persists for a small number of days.
        </li>
        <h3>Selling Personal Information</h3>
        Our Site sells Personal Information, as defined by the California
        Consumer Privacy Act of 2018 (“CCPA”). We share your Personal
        Information with service providers to help us provide our services and
        fulfill our contracts with you, as described above. For example:
        <li>
          We may share your Personal Information to comply with applicable laws
          and regulations, to respond to a subpoena, search warrant or other
          lawful request for information we receive, or to otherwise protect our
          rights.
        </li>
        <h3>Your rights</h3>
        <h5>GDPR</h5>
        If you are a resident of the EEA, you have the right to access the
        Personal Information we hold about you, to port it to a new service, and
        to ask that your Personal Information be corrected, updated, or erased.
        If you would like to exercise these rights, please contact us at
        privacy@moca-ny.org or by mail using the details provided below: PO Box
        #43, Armonk, NY 10504, United States Your Personal Information will be
        initially processed in Europe and then will be transferred outside of
        Europe for storage and further processing, including to Canada and the
        United States. For more information on how data transfers comply with
        the GDPR, see this link.
        <h5>CCPA</h5>
        If you are a resident of California, you have the right to access the
        Personal Information we hold about you (also known as the ‘Right to
        Know’), to port it to a new service, and to ask that your Personal
        Information be corrected, updated, or erased. If you would like to
        exercise these rights, please contact us at privacy@moca-ny.org. If you
        would like to designate an authorized agent to submit these requests on
        your behalf, please contact us at the address above.
        <h3>Cookies</h3>A cookie is a small amount of information that’s
        downloaded to your computer or device when you visit our Site. We use a
        number of different cookies, including functional, performance,
        advertising, and social media or content cookies. Cookies make your
        browsing experience better by allowing the website to remember your
        actions and preferences (such as login and region selection). This means
        you don’t have to re-enter this information each time you return to the
        site or browse from one page to another. Cookies also provide
        information on how people use the website, for instance whether it’s
        their first time visiting or if they are a frequent visitor.
        {/*[WILL - INSERT OTHER COOKIES OR TRACKING TECHNOLOGIES THAT YOU USE] */}
        The length of time that a cookie remains on your computer or mobile
        device depends on whether it is a “persistent” or “session” cookie.
        Session cookies last until you stop browsing and persistent cookies last
        until they expire or are deleted. Most of the cookies we use are
        persistent and will expire between 30 minutes and two years from the
        date they are downloaded to your device. You can control and manage
        cookies in various ways. Please keep in mind that removing or blocking
        cookies can negatively impact your user experience and parts of our
        website may no longer be fully accessible. Most browsers automatically
        accept cookies, but you can choose whether or not to accept cookies
        through your browser controls, often found in your browser’s “Tools” or
        “Preferences” menu. For more information on how to modify your browser
        settings or how to block, manage or filter cookies can be found in your
        browser’s help file or through such sites as: www.allaboutcookies.org.
        Additionally, please note that blocking cookies may not completely
        prevent how we share information with third parties such as our
        advertising partners. To exercise your rights or opt-out of certain uses
        of your information by these parties, please follow the instructions in
        the “Behavioural Advertising” section above.
        <h5>Do Not Track</h5>
        Please note that because there is no consistent industry understanding
        of how to respond to “Do Not Track” signals, we do not alter our data
        collection and usage practices when we detect such a signal from your
        browser.
        <h3>Changes</h3>
        We may update this Privacy Policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal,
        or regulatory reasons.
        <h3>Complaints</h3>
        As noted above, if you would like to make a complaint, please contact us
        by e-mail or by mail using the details provided under “Contact” above.
        If you are not satisfied with our response to your complaint, you have
        the right to lodge your complaint with the relevant data protection
        authority. You can contact your local data protection authority, or our
        supervisory authority here: <br />privacy@moca-ny.org
        <br />https://ico.org.uk/make-a-complaint/
        <br /> https://oag.ca.gov/contact/consumer-complaint-against-business-or-company
        <br />https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en
        <br />Last updated: January 2023
      </Box>
    </Box>
  );
};
