import { Box, Typography } from "@mui/material";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  bold: {
    fontWeight: 700,
  },
};

export const ContactInfo = () => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.bold}>MUSEUM OF CERAMIC ART</Typography>
      <Typography sx={styles.bold}>NEW YORK</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        <a
          href="mailto:info@moca-ny.org"
          style={{ textDecoration: "none", color: "white" }}
        >
          info@moca-ny.org
        </a>
      </Typography>
      <Typography sx={{ ...styles.bold, fontSize: "14px" }}>
        Mailing Address: P.O. Box 43 Armonk, NY 10504
      </Typography>
    </Box>
  );
};
