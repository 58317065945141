import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const styles = {
  container: {
    height: "450px",
    marginTop: { xs: "100px" },
    marginBottom: "100px",
    marginLeft: { xl: "20%", lg: "15%", xs: "10%" },
    marginRight: { xl: "20%", lg: "15%", xs: "10%" },
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  map: {
    height: "100%",
    overflow: "hidden",
    display: {
      xs: "none",
      md: "block",
    },
  },
  description: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%",
    marginLeft: { xs: "0px", md: "80px" },
    maxWidth: "475px",
  },
  bold: {
    fontWeight: 700,
    marginBottom: "25px",
  },
  italic: {
    fontStyle: "italic",
  },
  justify: {
    textAlign: { xs: "center", sm: "justify" },
  },
};

export const MapCTA = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.map}>
        <video
          width="450"
          height="450"
          controls={false}
          loop={true}
          autoPlay={true}
          muted={true}
        >
          <source src="./images/map.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box sx={styles.description}>
        <Typography variant="h4" sx={styles.bold}>
          CERAMIC WORLD DESTINATIONS
        </Typography>
        <Typography variant="h5" sx={{ ...styles.bold, ...styles.italic }}>
          The 'Google Map' of Ceramics
        </Typography>
        <Typography variant="h6" sx={{ ...styles.bold, ...styles.justify }}>
          TAKE A VIRTUAL TRIP AND DISCOVER THE WORLD OF CLAY WITH OUR
          INTERACTIVE MAP.
        </Typography>
        <Typography variant="body1" sx={{ ...styles.bold, ...styles.justify }}>
          Ceramic World Destinations (CWD) reveals the global, diverse, and
          universal appeal of clay. The thousands of destinations listed are an
          alternative means to “travel,” opening new opportunities, prospects,
          and inspirations.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#163275",
            height: "50px",
            borderRadius: "6px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        >
          <Link
            to="/cwd"
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            View Map
          </Link>
        </Button>
      </Box>
    </Box>
  );
};
