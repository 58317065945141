import { useState } from "react";
import { Dialog } from "../components/shared/Dialog";
import { Box, CircularProgress } from "@mui/material";

export const SubmitPage = (props) => {
  const [showMask, setShowMask] = useState(true);
  return (
    <>
      {showMask && (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <iframe
        onLoad={() => setShowMask(false)}
        className="airtable-embed"
        src="https://airtable.com/embed/shrDxzxahvslUlPGj?backgroundColor=pink"
        width="100%"
        title="submission"
        style={{
          background: "transparent",
          height: props.height || "100%",
          border: "none",
        }}
      ></iframe>
    </>
  );
};

export const Submit = (props) => {
  const { open, toggle } = props;
  return (
    <Dialog
      open={open}
      toggle={toggle}
      label="Submit"
      title="Submit a Destination"
      content={<SubmitPage height={"80vh"} />}
      contentStyles={{ padding: 0 }}
    />
  );
};

export default Submit;
