import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface NavLinksProps {
  classes: { [key: string]: SxProps<Theme> };
  handleClick?: () => void;
}

export const NavLinks = (props: NavLinksProps) => {
  const { classes, handleClick } = props;
  // list of links for the desktop banner
  const navLinks = [
    { name: "Home", link: "/" },
    { name: "CWD Map", link: "/cwd" },
    { name: "Explore", link: "https://explore.moca-ny.org" },
    { name: "About", link: "https://explore.moca-ny.org/about/" },
    {
      name: "Connect",
      link: "https://explore.moca-ny.org/connect/",
    },
    { name: "Donate", link: "https://explore.moca-ny.org/donate" },
  ];

  return (
    <Box sx={classes.container}>
      {navLinks.map((link, index) => {
        return (
          <Link
            key={link.link}
            style={{ textDecoration: "none", color: "black" }}
            onClick={handleClick}
            to={link.link}
          >
            <Typography sx={classes.link}>{link.name}</Typography>
          </Link>
        );
      })}
    </Box>
  );
};
